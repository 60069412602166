import { BetaState, Wailist } from '../models/BetaLaunch.model';
import { api } from './configs/axiosConfigs';

export const BetaLaunchAPI = {
  getBetaState: async function (): Promise<BetaState> {
    const response = await api.request<BetaState>({
      url: '/beta',
      method: 'GET',
    });

    return response.data;
  },

  joinWaitlist: async function (data: Wailist): Promise<Wailist> {
    const response = await api.request<Wailist>({
      url: '/waitlist',
      method: 'POST',
      data: {
        companyName: data.companyName,
        email: data.email,
        phone: data.phone,
        country: data.country
      }
    });

    return response.data;
  }
};
