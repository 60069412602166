import React, { useState } from 'react';
import { Button, Modal, Input, InputNumber, message, Select } from 'antd';
import { FaArrowRightLong } from 'react-icons/fa6';
import { CloseCircleOutlined } from '@ant-design/icons';
import ReactCountryFlag from 'react-country-flag';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
// import countryNames from 'react-phone-number-input/locale/en';
import './WaitlistModal.css';
import { BetaLaunchAPI } from '../../apis';
import { Wailist } from '../../models/BetaLaunch.model';
import { useNavigate } from 'react-router-dom';

interface CountryOption {
  value: string;
  label: React.ReactNode;
  countryCode: string;
}

interface WailistProps {
  buttonText: string;
}

const WaitlistModal = ({ buttonText }: WailistProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<Wailist>({
    companyName: '',
    email: '',
    country: 'NG',
    phone: '',
  });
  const navigate = useNavigate();

  // Get all countries for the dropdown
  const countries = getCountries();

  // Transform countries into options for the dropdown
  const countryOptions: CountryOption[] = countries.map(country => ({
    value: country,
    countryCode: getCountryCallingCode(country),
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ReactCountryFlag countryCode={country} svg style={{ marginRight: '8px', width: '24px', height: '16px' }} />
        {country}
      </div>
    ),
  }));

  // Get the selected country calling code
  const selectedCountryCode = formData.country ? `+${getCountryCallingCode(formData.country)}` : '+234'; // Default to Nigeria (+234)

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value: string | null) => {
    setFormData(prev => ({
      ...prev,
      phone: value || '',
    }));
  };

  const handleCountryChange = (value: string) => {
    setFormData(prev => ({
      ...prev,
      country: value,
    }));
  };

  const onSubmit = async () => {
    try {
      if (!formData.companyName || !formData.email || !formData.country) {
        message.error('Please fill in all required fields');
        return;
      }

      await BetaLaunchAPI.joinWaitlist({
        companyName: formData.companyName,
        email: formData.email,
        country: formData.country,
        phone: formData.phone ? `${selectedCountryCode}${formData.phone}` : '',
      });

      setIsModalOpen(false);
      message.success('Successfully joined the waitlist!');
    } catch (error) {
      message.error('Failed to join waitlist. Please try again.');
    }
  };

  return (
    <>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          if (buttonText === 'Join Our Beta Launch') {
            const launchDate = localStorage.getItem('launchDate');
            if (launchDate === '0') {
              navigate(process.env.REACT_APP_WEB_APP_URL || '');
            } else if (launchDate && parseInt(launchDate) >= 1) {
              showModal();
            }
          } else {
            showModal();
          }
        }}
      >
        {buttonText}
        <FaArrowRightLong />
      </Button>
      <Modal
        centered
        open={isModalOpen}
        onOk={onSubmit}
        onCancel={handleCancel}
        closeIcon={<CloseCircleOutlined />}
        width={window.innerWidth >= 768 ? 644 : ''}
        footer={[
          <Button key="submit" type="primary" onClick={onSubmit}>
            Confirm
          </Button>,
        ]}
      >
        <h2>Experience it first</h2>
        <p>Get a head start on seamless service monitoring!</p>

        <form className="modal-inputs">
          <Input
            size="large"
            variant="filled"
            placeholder="Company Name"
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            required
          />
          <Input
            size="large"
            variant="filled"
            placeholder="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <Input
            size="large"
            variant="filled"
            addonBefore="Country of Residence"
            name="country"
            onChange={handleInputChange}
            className="residence"
            addonAfter={
              <div className="country-select-container">
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={formData.country}
                  onChange={handleCountryChange}
                  options={countryOptions}
                  optionLabelProp="label"
                  placeholder="Country of Residence"
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  popupMatchSelectWidth={false}
                  className="residence-select"
                  // dropdownRender={menu => (
                  //   <div>
                  //     <div>
                  //       <Input.Search placeholder="Search country" />
                  //     </div>
                  //     {menu}
                  //   </div>
                  // )}
                />
              </div>
            }
            required
          />

          {/* Phone Number Input with Country Code */}
          <div className="phone-input-container">
            <InputNumber
              size="large"
              variant="filled"
              placeholder="Phone Number"
              type="tel"
              name="phone"
              value={formData.phone}
              controls={false}
              stringMode={true}
              onChange={handlePhoneChange}
              addonBefore={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ReactCountryFlag
                    countryCode={formData.country || 'NG'}
                    svg
                    style={{ marginRight: '8px', width: '20px' }}
                  />
                  {selectedCountryCode}
                </div>
              }
              style={{ width: '100%' }}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default WaitlistModal;
