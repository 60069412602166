import { Select } from 'antd';
import './STSelect.css';
import { ReactComponent as STGroupSvg } from '../../assets/icons/group.svg';
import { ReactComponent as STArrowDownSvg } from '../../assets/icons/arrow-down.svg';
import Icon from '@ant-design/icons';
import { useState } from 'react';

interface STSelectProps {
  items: { key: number; name: string }[];
  onChange: (e: number | undefined) => void;
}

export function STSelect({ items, onChange }: STSelectProps) {
  const [selectedValue, setSelectedValue] = useState<number | undefined>(undefined);

  const handleChange = (value: number | undefined) => {
    setSelectedValue(value);
    onChange(value);
  };
  return (
    <div className="STSelect__wrapper">
      <div className="STSelect__icon_wrapper">
        <Icon className="icon-sm icon-light-grey" component={STGroupSvg} />
      </div>
      <Select
        style={{ width: '100%' }}
        allowClear={true}
        bordered={false}
        placeholder="Category"
        onChange={handleChange}
        suffixIcon={<Icon className="suffix-icon" component={STArrowDownSvg} />}
        options={items.map(item => {
          return {
            value: item.key,
            label: item.name,
            className: selectedValue === item.key ? 'selected-item' : '',
          };
        })}
      />
    </div>
  );
}
