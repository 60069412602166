import { PublicService, getHealthStatusColor, getHealthStatusText } from '../../models';
import './StatusCard.css';
import { ReactComponent as STStatusSvg } from '../../assets/icons/status.svg';
import { ReactComponent as STChartSvg } from '../../assets/icons/chart.svg';
import Icon from '@ant-design/icons';
import { Popover } from 'antd';
import { StatusCardHeader } from '.';
import { toStartUpperCase } from '../../utils';
import { PublicAccountService } from '../../models/PublicAccountServices.model';
import { SearchForm } from '../form';
import { useSelector } from 'react-redux';
import { STState } from '../../stStore';

interface StatusCardProps {
  service: PublicService | PublicAccountService;
  isFavorite: boolean;
  groupTitle?: string;
  onClickFavorite: (id: string, category: string) => void;
  onClickServiceStatus: (id: string) => void;
  handleFormSubmit?: ({ name, category }: { name?: string; category?: string }) => void;
}

export function StatusCard({
  service,
  isFavorite,
  groupTitle,
  onClickFavorite,
  onClickServiceStatus,
  handleFormSubmit,
}: StatusCardProps) {
  const categories = useSelector((state: STState) => state.categories);

  const healthInfoPopover = service.healthInfo && service.healthStatus === 'INCIDENT' && (
    <div>
      <p>{service.healthInfo}</p>
    </div>
  );

  return (
    <div className="StatusCard__wrapper">
      {groupTitle && (
        <div className="card-banner">
          <h2 className="group-title">{toStartUpperCase(groupTitle)}</h2>
          {categories?.loading === 'succeeded' && (
            <SearchForm
              onSubmit={(name, category) => handleFormSubmit && handleFormSubmit({ name, category })}
              categories={categories.values}
              groupTitle={groupTitle}
            />
          )}
        </div>
      )}

      <div className="status-card half-px-border">
        <StatusCardHeader
          service={service}
          isFavorite={isFavorite}
          onClickFavorite={onClickFavorite}
          onClickTitle={() => onClickServiceStatus(service.serviceId)}
        />
        <div className="footer-title">Status</div>
        <div className="flex-row footer-content">
          <div>
            <Popover content={healthInfoPopover}>
              <Icon
                component={STStatusSvg}
                style={{
                  color: toStartUpperCase(getHealthStatusColor(service.healthStatus || 'HEALTHY')),
                  fontSize: '32px',
                }}
              />
            </Popover>
          </div>
          <div>{toStartUpperCase(getHealthStatusText(service.healthStatus || 'HEALTHY'))}</div>
          <Icon
            onClick={() => onClickServiceStatus(service.serviceId)}
            component={STChartSvg}
            style={{ color: '#cac7d5', fontSize: '24px' }}
          />
        </div>
      </div>
    </div>
  );
}
