import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL!,
});

// defining a custom error handler for all APIs
const errorHandler = (error: any) => {
  const statusCode = error.response?.status;
  if (error.code === 'ERR_CANCELED') {
    // TODO: handle error
    return Promise.resolve();
  }
  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }
  return Promise.reject(error);
};

// Request interceptor
api.interceptors.request.use(
  config => {
    // Modify config before sending the request
    config.headers['Accept'] = 'application/json';

    // Remove empty query parameters for GET requests
    if (config.method === 'get' && config.params) {
      config.params = Object.fromEntries(
        Object.entries(config.params).filter(([_, value]) => value !== undefined && value !== null && value !== '')
      );
    }

    return config;
  },
  error => {
    // Handle request error
    return Promise.reject(error);
  }
);
// Response interceptor

api.interceptors.response.use(
  response => response,
  error => {
    // Handle response error
    return Promise.reject(error);
  }
);

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, error => {
  return errorHandler(error);
});
