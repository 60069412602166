export type HealthStatusType = 'INCIDENT' | 'HEALTHY' | 'OUTAGE';

export interface PublicServiceEvent {
  healthInfo: string;
  healthStatus: HealthStatusType;
  lastStatusCheck: number;
  serviceId: string;
}

export interface PublicService {
  serviceId: string;
  clientId?: string;
  name: string;
  healthy?: boolean;
  healthStatus?: HealthStatusType;
  healthInfo?: string;
  category?: string;
  logoUrl?: string;
  lastStatusCheck?: number;
}

export interface PublicServiceResponse {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
  data: PublicService[];
}

export interface CurrentServicesState {
  values: [string, PublicServiceResponse][];
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  loadingCategory?: string;
}

export interface PublicServiceChart {
  data: {
    date: string;
    totalCheckCount: number;
    totalIncidentCount: number;
    totalSuccessCount: number;
  }[];
  summary: {
    reportPercentage: number;
    totalCheckCount: number;
    totalFailureCount: number;
    totalIncidentCount: number;
    totalSuccessCount: number;
  };
}

export interface PublicServiceHistory {
  createdOn: number;
  healthStatus: HealthStatusType;
  info: string;
  responseTime: string;
}

export interface PublicServiceHistoryResponse {
  data: PublicServiceHistory[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
}

export const getHealthStatusColor = (status: HealthStatusType) => {
  const map: Record<HealthStatusType, string> = {
    INCIDENT: '#ffa500',
    HEALTHY: '#50c878',
    OUTAGE: '#ff5733',
  };

  return map[status];
};

export const getHealthStatusText = (status: HealthStatusType) => {
  const map: Record<HealthStatusType, string> = {
    INCIDENT: 'Incident',
    HEALTHY: 'Operational',
    OUTAGE: 'Downtime',
  };

  return map[status];
};

export class PublicServiceEventFactory {
  static fromString(data: any): PublicServiceEvent {
    try {
      const res = JSON.parse(data);
      return res;
    } catch (e) {
      return {
        serviceId: 'DOES_NOT_EXIST',
      } as PublicServiceEvent;
    }
  }
}
