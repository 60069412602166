import { useCallback, useEffect, useRef } from 'react';
import { Flex, Spin, message } from 'antd';
import { StatusGroup } from '../components/ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { STState } from '../stStore';
import { fetchCategories } from '../features/categoriesSlice';
import { toggleFavorite } from '../features/favoritesSlice';
import { initServices, loadMore, filterServices, updateService } from '../features/servicesSlice';
import {
  initServices as accountInitServices,
  loadMore as accountLoadMore,
  filterServices as accountFilterServices,
  updateService as accountUpdateService,
} from '../features/accountServicesSlice';
import { useSTDispatch } from '../stStore';
import { PublicServiceEventFactory } from '../models';
import { AccountStatusGroup } from '../components/ui/AccountStatusGroup';
import { formatHexCode } from '../utils/hexcode-helper';
import JoinBeta from '../components/ui/JoinBeta';
import { Link } from 'react-router-dom';
import { objToURLSearchParams } from '../utils/url-search-params-helper';

function PublicSearch() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const categories = useSelector((state: STState) => state.categories);
  const favorites = useSelector((state: STState) => state.favorites);
  const services = useSelector((state: STState) => state.services);
  const filter = useSelector((state: STState) => state.filter);
  const accountServices = useSelector((state: STState) => state.accountServices);
  const account = useSelector((state: STState) => state.account.name);
  const clientsDashboard = useSelector((state: STState) => state.clients.data);
  const dispatch = useSTDispatch();

  const currentServices = account ? accountServices : services;
  const currentInitServices = account ? accountInitServices : initServices;
  const currentLoadMore = account ? accountLoadMore : loadMore;
  const currentFilterServices = account ? accountFilterServices : filterServices;
  const currentUpdateService = account ? accountUpdateService : updateService;
  const [searchParams, setSearchParams] = useSearchParams();
  const favoritesRef = useRef(favorites.values);

  const filterServicesByParams = useCallback(
    (name: string, category: string) => {
      if (name?.length || category?.length) {
        return dispatch(currentFilterServices({ name, category }) as any)
          .unwrap()
          .catch((err: any) => {
            messageApi.error(err.message);
          });
      } else {
        return dispatch(
          currentInitServices({
            categories: categories.values.map(c => c.name),
            favorites: favoritesRef.current.map(f => f.id),
          }) as any
        )
          .unwrap()
          .catch((err: any) => {
            messageApi.error(err.message);
          });
      }
    },
    [categories.values, currentInitServices, currentFilterServices, messageApi, dispatch]
  );

  useEffect(() => {
    favoritesRef.current = favorites.values;
  }, [favorites.values]);

  useEffect(() => {
    if (categories.loading === 'idle') {
      dispatch(fetchCategories())
        .unwrap()
        .then(res => {
          dispatch(
            currentInitServices({
              categories: res.map(c => c.name),
              favorites: favorites.values.map(f => f.id),
            }) as any
          )
            .unwrap()
            .catch((err: any) => {
              messageApi.error(err.message);
            });
        })
        .catch((err: any) => {
          messageApi.open({
            type: 'error',
            content: err?.message,
          });
        });
    }
  }, [dispatch, categories, messageApi, favorites.values, currentInitServices]);

  useEffect(() => {
    let es: EventSource | undefined;
    let retryTimeout: ReturnType<typeof setTimeout> | undefined;

    const openEventSource = () => {
      es = new EventSource(process.env.REACT_APP_API_URL! + '/events');

      es.addEventListener('Status Update', e => {
        const data = PublicServiceEventFactory.fromString(e.data);
        dispatch(currentUpdateService(data));
      });

      es.addEventListener('error', e => {
        if (es?.readyState === EventSource.CLOSED || es?.readyState === EventSource.CONNECTING) {
          es.close();
          setTimeout(() => openEventSource(), 5000);
        }
      });
    };

    openEventSource();

    return () => {
      if (es) {
        es.close();
      }
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [dispatch, currentUpdateService]);

  const handleFormSubmit = ({ name, category }: { name?: string; category?: string }) => {
    const query = objToURLSearchParams({ name: name || '', category: category || '' });
    setSearchParams(query);
  };

  useEffect(() => {
    const name = searchParams.get('name') || '';
    const category = searchParams.get('category') || '';

    filterServicesByParams(name, category);
  }, [searchParams, filterServicesByParams]);

  const handleLoadMore = (category: string) => {
    dispatch(currentLoadMore({ category, filter }) as any)
      .unwrap()
      .catch((err: any) => {
        messageApi.error(err.message);
      });
  };

  const getTargetUrl = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return '/coming-soon';
    } else {
      return process.env.REACT_APP_WEB_APP_URL;
    }
  };

  return (
    <>
      {contextHolder}
      <div className="Search__wrapper">
        <div className="plr-page pt-lg">
          <div className="compact">
            <h1
              className="accent-900-text"
              style={{
                color: clientsDashboard?.publicDashboardCustomization?.headerColor
                  ? formatHexCode(clientsDashboard.publicDashboardCustomization?.headerColor)
                  : '',
              }}
            >
              {clientsDashboard?.publicDashboardCustomization?.headerText ? (
                clientsDashboard?.publicDashboardCustomization?.headerText
              ) : (
                <>
                  Keep Track. <br /> Keep Growing.
                </>
              )}
            </h1>

            {account && account !== 'test' && (
              <h4>
                {clientsDashboard?.publicDashboardCustomization?.subHeaderText ? (
                  clientsDashboard.publicDashboardCustomization?.subHeaderText + '. '
                ) : (
                  <>
                    Status Trust empowers companies to effortlessly monitor and optimize the status of their operations
                    for enhanced efficiency and success.{' '}
                    <Link to={getTargetUrl()!} target="_blank" className="semi-bold">
                      Get Started
                    </Link>
                  </>
                )}
              </h4>
            )}

            {!account && account !== 'test' && <JoinBeta />}
          </div>
        </div>
        <div>
          {categories.loading === 'loading' || currentServices.loadingCategory === 'all' ? (
            <Flex justify="center">
              <Spin size="large" />
            </Flex>
          ) : (
            currentServices.values.map(([category, serviceResponse], index) => {
              return serviceResponse.data.length > 0 ? (
                account ? (
                  <AccountStatusGroup
                    onClickFavorite={(id: string, category: string) => {
                      dispatch(toggleFavorite({ id, category }));
                    }}
                    onClickServiceStatus={(id: string) => navigate(id)}
                    onClickLoadMore={() => handleLoadMore(category)}
                    key={category}
                    title={category}
                    favorites={favorites.values.map(f => f.id)}
                    serviceList={serviceResponse.data}
                    hasMore={serviceResponse.page < serviceResponse.totalPageCount - 1}
                    loadMoreVisible={category !== 'Favorites'}
                    bgColor={index % 2 === 0 ? 'transparent' : '#f5f5f5'}
                    isLoading={currentServices.loadingCategory === category}
                    handleFormSubmit={handleFormSubmit}
                  />
                ) : (
                  <StatusGroup
                    onClickFavorite={(id: string, category: string) => {
                      dispatch(toggleFavorite({ id, category }));
                    }}
                    onClickServiceStatus={(id: string) => navigate(id)}
                    onClickLoadMore={() => handleLoadMore(category)}
                    key={category}
                    title={category}
                    favorites={favorites.values.map(f => f.id)}
                    serviceList={serviceResponse.data}
                    hasMore={serviceResponse.page < serviceResponse.totalPageCount - 1}
                    loadMoreVisible={category !== 'Favorites'}
                    bgColor={index % 2 === 0 ? 'transparent' : '#f5f5f5'}
                    isLoading={currentServices.loadingCategory === category}
                    handleFormSubmit={handleFormSubmit}
                  />
                )
              ) : (
                <div
                  key={index}
                  className="not-found-message"
                  style={{ padding: '20px', textAlign: 'center', fontSize: '30px' }}
                >
                  <p>"Service Not Found"</p>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

export default PublicSearch;
