import { PublicAccountClients } from '../models/PublicAccountClients.model';
import { api } from './configs/axiosConfigs';
import { getAccountFromSubdomain } from '../utils/account-helper';

export const PublicAccountClientsAPI = {
  getClientsCategory: async function (): Promise<PublicAccountClients | null> {
    const account = getAccountFromSubdomain();
    if (!account) {
      return null;
    }

    const response = await api.request<any>({
      url: `/public/${account}/clients`,
      method: 'GET',
    });

    return response.data;
  },
};
