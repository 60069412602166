import { StatusCard } from './StatusCard';
import './StatusGroup.css';
import { PublicAccountService } from '../../models/PublicAccountServices.model';
import { Button, Flex, Spin } from 'antd';

interface StatusGroupProps {
  title: string;
  favorites: string[];
  serviceList: PublicAccountService[];
  bgColor: 'transparent' | '#f5f5f5';
  hasMore: boolean;
  loadMoreVisible: boolean;
  isLoading: boolean;
  onClickFavorite: (id: string, category: string) => void;
  onClickServiceStatus: (id: string) => void;
  onClickLoadMore: () => void;
  handleFormSubmit?: ({ name, category }: { name?: string; category?: string }) => void;
}

export function AccountStatusGroup({
  title,
  favorites,
  serviceList,
  bgColor,
  hasMore,
  loadMoreVisible,
  isLoading,
  onClickFavorite,
  onClickServiceStatus,
  onClickLoadMore,
  handleFormSubmit,
}: StatusGroupProps) {
  return (
    <div className="StatusGroup__wrapper plr-page pt-lg pb-lg" style={{ backgroundColor: bgColor }}>
      <div className="content">
        {serviceList.map((service, index) => {
          return (
            <StatusCard
              onClickFavorite={onClickFavorite}
              onClickServiceStatus={onClickServiceStatus}
              service={service}
              isFavorite={favorites.indexOf(service.serviceId) > -1}
              key={service.serviceId}
              groupTitle={index === 0 ? title : undefined}
              handleFormSubmit={handleFormSubmit}
            />
          );
        })}
      </div>
      {isLoading && (
        <Flex justify="center">
          <Spin size="large" />
        </Flex>
      )}
      {loadMoreVisible && (
        <div className="StatusGroup__btn align-center">
          <Button disabled={!hasMore || isLoading} onClick={onClickLoadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
}
