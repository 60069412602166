import { useReducer } from 'react';
import { PublicService } from '../models';

interface ActionType {
  type: 'ERROR' | 'LOADING' | 'LOADED';
  payload?: PublicService;
  error?: Error;
}

interface ServiceState {
  data: PublicService;
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string;
}

const initialState: ServiceState = {
  loading: 'idle',
  error: undefined,
  data: {
    serviceId: '',
    category: '',
    healthStatus: 'HEALTHY',
    healthy: true,
    name: '',
  },
};

const serviceDetailsReducer = (state: ServiceState, action: ActionType): ServiceState => {
  switch (action.type) {
    case 'ERROR':
      return {
        loading: 'failed',
        error: action.error?.message || 'HTTP error',
        data: initialState.data,
      };
    case 'LOADED':
      return {
        data: action.payload!,
        loading: 'succeeded',
      };
    case 'LOADING':
      return {
        ...initialState,
        loading: 'loading',
      };
    default:
      throw new Error();
  }
};

export const useService = (): [state: ServiceState, dispatch: React.Dispatch<ActionType>] => {
  const [state, dispatch] = useReducer(serviceDetailsReducer, {
    ...initialState,
  });

  return [state, dispatch];
};
