import { useEffect, useState } from 'react';
import Counter from './Counter';
import './JoinBeta.css';
import { BetaLaunchAPI } from '../../apis';
import WaitlistModal from './WaitlistModal';

const JoinBeta = ({ className }: any) => {
  const [showSlots, setShowSlots] = useState<number>(0);

  useEffect(() => {
    BetaLaunchAPI.getBetaState()
      .then(res => {
        setShowSlots(res.availableBetaSlots);
      })
      .catch(error => {
        console.log(error);
      });
  }, [showSlots]);

  const launchDate = localStorage.getItem('launchDate');
  const isLaunchDay = launchDate === '0';
  const hasAvailableSlots = showSlots > 0;

  const buttonText = isLaunchDay && hasAvailableSlots ? 'Join Our Beta Launch' : 'Join Our Waitlist';

  return (
    <div className={`joinBeta ${className}`}>
      <Counter />
      <div className="btnGroup">
        <WaitlistModal buttonText={buttonText} />

        {isLaunchDay && hasAvailableSlots && (
          <div className="slot">
            <span className="slotCount">{showSlots}</span> Slots Left
          </div>
        )}
      </div>
    </div>
  );
};

export default JoinBeta;
