import { useState } from 'react';
import { STSelect } from './STSelect';
import { STInput } from './STInput';
import './SearchForm.css';
import { PublicCategory } from '../../models';
import { toStartUpperCase } from '../../utils';
import { useSelector } from 'react-redux';
import { STState } from '../../stStore';

interface SearchFormProps {
  categories: PublicCategory[];
  onSubmit: (e: string, category: string | undefined) => void;
  groupTitle?: string;
}

export function SearchForm({ onSubmit, categories, groupTitle }: SearchFormProps) {
  const [filterText, setFilterText] = useState<string>('');
  const [category, setCategory] = useState<number | undefined>(undefined);
  const clientsDashboard = useSelector((state: STState) => state.clients.data);

  return (
    <div className="flex-row SearchForm__wrapper compact half-px-border">
      <div className="flex-row input-wrapper">
        {!clientsDashboard && (
          <div className="w50 category">
            <STSelect
              onChange={category => setCategory(category)}
              items={categories.map(c => {
                return { key: c.key, name: toStartUpperCase(c.name) };
              })}
            />
          </div>
        )}

        <div className="w50 search">
          <STInput
            placeholder={`Search ${
              groupTitle ? groupTitle.charAt(0).toUpperCase() + groupTitle.slice(1).toLowerCase() : ''
            }`}
            onChange={text => setFilterText(text)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onSubmit(filterText, categories.find(c => c.key === category)?.name);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
