import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PublicAccountClients } from '../models/PublicAccountClients.model';
import { PublicAccountClientsAPI } from '../apis/PublicAccountClientsAPI';

// Define the state interface
export interface ClientsState {
  data: PublicAccountClients | null;
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Initial state
const initialState: ClientsState = {
  data: null,
  loading: 'idle',
  error: null,
};

// Create an async thunk for fetching clients
export const fetchClientsDashboard = createAsyncThunk(
  'clients/fetchCategory',
  async (account: string, { rejectWithValue }) => {
    try {
      const response = await PublicAccountClientsAPI.getClientsCategory();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Create the slice
export const accountClientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchClientsDashboard.pending, state => {
      state.loading = 'loading';
      state.error = null;
    });
    builder.addCase(fetchClientsDashboard.fulfilled, (state, action: PayloadAction<PublicAccountClients>) => {
      state.loading = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(fetchClientsDashboard.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload as string;
    });
  },
});

// Export the reducer to add to the store
export default accountClientsSlice.reducer;
