import './STLayout.css';
import { Outlet } from 'react-router-dom';
import { STHeader } from '../components/ui';
import { setAccount } from '../features/accountSlice';
import { useSTDispatch } from '../stStore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { STState } from '../stStore';
import { fetchClientsDashboard } from '../features/accountClientsSlice';
import { formatHexCode } from '../utils/hexcode-helper';
import { getAccountFromSubdomain } from '../utils/account-helper';

export function STLayout() {
  const dispatch = useSTDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const account = getAccountFromSubdomain();
    if (account && account !== 'test' && account !== 'statustrust') {
      dispatch(setAccount(account));
    }
  }, [dispatch]);

  const account = useSelector((state: STState) => state.account.name);
  const clientsDashboard = useSelector((state: STState) => state.clients.data);

  useEffect(() => {
    if (account && !clientsDashboard) {
      dispatch(fetchClientsDashboard(account)).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch, account, clientsDashboard]);

  const formattedColor = clientsDashboard?.publicDashboardCustomization?.backgroundColor
    ? formatHexCode(clientsDashboard.publicDashboardCustomization?.backgroundColor)
    : '';

  const gradientBackground = formattedColor
    ? `linear-gradient(180deg, ${formattedColor} 0%, var(--white) 100%)`
    : 'linear-gradient(180deg, var(--bg-accent) 0%, var(--white) 100%)';

  if (loading) {
    return <></>;
  }

  return (
    <>
      <div
        className="STLayout_background"
        style={{
          background:
            clientsDashboard?.publicDashboardCustomization?.backgroundType !== 'SOLID'
              ? gradientBackground
              : formattedColor,
        }}
      ></div>
      <div className="STLayout_wrapper">
        <STHeader />
        <Outlet />
      </div>
    </>
  );
}
