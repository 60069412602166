import './STInput.css';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

interface InputProps {
  placeholder: string;
  onChange: (e: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function STInput({ placeholder, onChange, onKeyDown }: InputProps) {
  return (
    <Input
      size="large"
      placeholder={placeholder}
      bordered={false}
      width={200}
      prefix={<SearchOutlined style={{ fontSize: '18px', color: '#cac7d5' }} />}
      maxLength={20}
      allowClear={true}
      onChange={event => onChange(event.target.value)}
      onKeyDown={event => onKeyDown?.(event)}
    />
  );
}
