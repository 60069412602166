export const getAccountFromSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  if (parts.length < 3) {
    return null;
  }

  if (parts[0] === 'www') {
    return parts.length > 3 ? parts[1] : null;
  }

  return parts[0];
};
