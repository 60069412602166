import { Navbar } from '../components/website/Navbar';
import DasboardImage from '../assets/website/dashboard.svg';
import Cards from '../components/website/Cards';
import Accordian from '../components/website/Accordian';
import Contact from '../components/website/Contact';
import Footer from '../components/website/Footer';
import './Website.css';
import JoinBeta from '../components/ui/JoinBeta';

export function Website() {
  return (
    <div className="Website">
      <Navbar />
      <section className="about">
        <div className="grid">
          <h1 className="header">
            Keep track of <br /> everything.
          </h1>
          <div>
            <p className="text">
              Our mission is to empower businesses like yours by providing cutting-edge solutions to keep track of your
              services and elevate your brand's performance.
            </p>
            <JoinBeta className={'website'} />

            {/* <Button onClick={() => window.open(getTargetUrl(), '_blank')} type="primary">
              <p>Get Started</p> <FaArrowRightLong className="website-btnicon" />
            </Button> */}
          </div>
        </div>
        <img src={DasboardImage} alt=" " className="dashboard-image" />
        <Cards />
      </section>
      {/* <section className="testimonials">
        <Carousel />
      </section> */}
      <section className="faqs">
        <Accordian />
      </section>
      <section className="contact">
        <Contact />
      </section>
      <Footer />
    </div>
  );
}
