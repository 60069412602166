import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import './Counter.css';

dayjs.extend(duration);

const Counter = () => {
  const targetDate = dayjs('2025-04-15 00:00:00');

  const calculateTimeLeft = useCallback(() => {
    const now = dayjs();
    const diff = targetDate.diff(now);
    localStorage.setItem('launchDate', diff.toString());

    if (diff <= 0) {
      return { days: '00', hours: '00', minutes: '00', seconds: '00' };
    }

    const timeLeft = dayjs.duration(diff);

    return {
      days: String(timeLeft.days()).padStart(2, '0'),
      hours: String(timeLeft.hours()).padStart(2, '0'),
      minutes: String(timeLeft.minutes()).padStart(2, '0'),
      seconds: String(timeLeft.seconds()).padStart(2, '0'),
    };
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return (
    <div className="counter">
      {Object.entries(timeLeft).map(([label, value]) => (
        <div className={`section ${label}`} key={label}>
          <div className="group-card">
            <div className="count-card">{value[0]}</div>
            <div className="count-card">{value[1]}</div>
          </div>
          <p>{label.toUpperCase()}</p>
        </div>
      ))}
    </div>
  );
};

export default Counter;
